var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editable && !_vm.disabled
    ? _c("c-btn", {
        attrs: { label: "상세업무", icon: "info_outline" },
        scopedSlots: _vm._u(
          [
            {
              key: "tooltip",
              fn: function () {
                return [
                  _c(
                    "q-popup-proxy",
                    {
                      attrs: {
                        "transition-show": "scale",
                        "transition-hide": "scale",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row impr-relation-table-info-row" },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card bg-primary widget-chart text-white card-border impr-relation-table-info-header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "impr-relation-table-info-title",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "icon-wrapper rounded-circle impr-relation-table-info-title-icon",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "icon-wrapper-bg bg-white opacity-1",
                                        }),
                                        _c("i", {
                                          staticClass: "lnr-bubble text-white",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "impr-relation-table-info-title-detail",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.improveRelationTask
                                              .relationTableTitle
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.isExistsTask
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-white opacity-8 impr-relation-table-info-navi",
                                      },
                                      [
                                        _c("span", { staticClass: "pl-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.improveRelationTask
                                                .relationTableNavi
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm.isExistsTask
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card bg-white widget-chart card-border",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "impr-relation-table-info-contents",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$comm.convertEnter(
                                            _vm.improveRelationTask
                                              .relationTableContents
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2613049299
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }